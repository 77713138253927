/* global window */
import path from 'ramda/src/path';
import emptyVideoDetail from '../../video/services/emptyVideoDetail.json';

const getEnv = (domainName = 'qa1') => (domainName.startsWith('www') ? '' : `${domainName.slice(0, 3)}-`);
class ClientRequest {
  constructor(domainName) {
    this.publicApiBase = `https://${getEnv(domainName)}publicapi.misitemgr.com`;
    this.domainName = domainName;
    this.impImages = domainName.includes('qa2') || domainName.includes('qa1')
      ? "https://ugcuat.mcclatchy.com/image-ai"
      : "https://ugc.mcclatchy.com/image-ai";
    this.impVideos = domainName.includes('qa2') || domainName.includes('qa1')
      ? "https://ugcuat.mcclatchy.com/media-ai"
      : "https://ugc.mcclatchy.com/media-ai";
    this.emailApi = {
      url: "https://api.emailoversight.com/api/emailvalidation",
      token: "645da595-272c-4e7b-b409-42a6780418ba",
      dataAxleListId: 148527
    };
  }
  async doFetch(url) {
    const response = await fetch(url, { headers: { 'X-Forwarded-Host': this.domainName } });
    if (response.ok) return response.json();
    throw new Error('Requested section not found. The section does not exist. Please adjust your request and try again.');
  }

  async doPost(url, headers, body, searchParams) {
    const response = await fetch(
      url + "?" + new URLSearchParams(searchParams),
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );
    if (response.ok) return response.json();
    throw new Error("POST Request failed");
  }

  // fetches videos data from IMP  
  async getVideosFromImp(accountName,) {
    const params = new URLSearchParams({
      accountName: accountName,
    });
    const apiUrl =
      `${this.impVideos}/media-results/connatix/getVideo?` +
      params.toString();
    const response = await fetch(apiUrl);
    const responseData = await response.json();

    return responseData.search.items;
  }
  // fetches images data from IMP  
  async getImagesFromImp(accountName) {
    const params = new URLSearchParams({
      accountName: accountName,
    });
    const apiImgUrl =
      `${this.impImages}/image-analysis/cue/getImages?` +
      params.toString();
    const response = await fetch(apiImgUrl);
    const responseData = await response.json();
    return responseData;
  }
  // uploads video to IMP  
  async sendVideoToIMP(formData, searchParams) {
    const url = `${this.impVideos}/uploads/uploadFile`;
    const headers = {
      accept: "*/*",
      "X-Forwarded-Host": this.domainName,
    };
    await this.doPost(url, headers, formData, searchParams);
    return true;
  }
  // uploads image to IMP  
  async sendImageToIMP(formData, searchParams) {
    const url = `${this.impImages}/image-analysis/uploadImageFile`;
    const headers = {
      accept: "*/*",
      "X-Forwarded-Host": this.domainName,
    };
    const response = await this.doPost(url, headers, formData, searchParams);
    return true;
  }
  //email validation
  async validateEmailWithDataAxle(email) {
    const { url, token, dataAxleListId } = this.emailApi;
    const headers = {
      "Content-Type": "application/json",
      ApiToken: token,
    };
    const data = {
      ListId: dataAxleListId,
      Email: email,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      const emailValidationResponse = await response.json();
      const { ResultId } = emailValidationResponse;
      if (response.ok && ResultId !== 1) {
        return -1; // in case of unverified email, return -1
      }
      return 1; // in case of verified email, return 1
    } catch (error) {
      return 0; // in case of api failure, return 0
    }
  }
  // Profile request
  buildProfilePath(id, page) {
    const pubName = this.domainName.split('.')[1];
    return `${this.publicApiBase}/webapi-public/content/${id}/usages/${page}?pub=${pubName}`;
  }
  getLatest(authorId, page = 1) {
    return this.doFetch(this.buildProfilePath(authorId, page));
  }
  // Search request
  buildSearchPath(query, pubName) {
    return `${this.publicApiBase}/webapi-public/v2/publications/${pubName}/search`;
  }

  // Search Path for new Search API
  buildNewSearchPath() {
    console.log("buildNewSearchPath() called, returning: /api/proxy/search");
    return `${window.location.origin}/api/proxy/search`; // Or some other way to get the base URL
  }

  //WPS-4988 - converting get to post request changes
  async getSearch(term, start, rows, orderBy, pubName) {
    console.log("PUBLICATION NAME NOW", pubName);
    if (pubName === "miamiherald") { // Check if pubName is "miami"
      console.log("Using new search API for pubName:", pubName);
      const newQueryParam = {
        query: term, // correct order
        publication: pubName, // correct order
        order_by: orderBy || 'relevance', // correct order
        start: start, // correct order
        rows: rows // correct order
      };
      console.log("Request Body:", JSON.stringify(newQueryParam));
      try {
        const response = await fetch(this.buildNewSearchPath(), {  // Call buildNewSearchPath and the new API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newQueryParam)
        });
        return await response.json();
      } catch (error) {
        console.error("Error in new search API:", error);
        throw error; // Re-throw the error to be handled by the caller
      }

    } else { // For other publications, use the original search method
      console.log("Inside ELSE PART OF getSearch()");
      const buildqueryparams = { 'q': term, 'start': start, 'rows': rows }
      if (orderBy !== '' && orderBy !== 'relevance') {
        buildqueryparams.sort = orderBy;
      }
      try {
        const response = await fetch(this.buildSearchPath(buildqueryparams, pubName), {
          method: 'POST',
          headers: {
            'X-Forwarded-Host': this.domainName
          },
          body: JSON.stringify(buildqueryparams),
        });
        return await response.json();
      } catch (error) {
        console.error("Error in original search API:", error);
        throw error; // Re-throw the error to be handled by the caller
      }
    }
  }


  // Topics request
  buildTagsPath(tag, page, pub) {
    const pubQuery = pub !== '' ? `?published_to=${pub}` : '';
    return `${this.publicApiBase}/webapi-public/v2/tags/${tag}/${page}${pubQuery}`;
  }
  getTagsContent(tag, page = 1, pub = '') {
    return this.doFetch(this.buildTagsPath(tag, page, pub));
  }
  //  Video content request
  getVideoDetailEndPoint(contentID) {
    return `${this.publicApiBase}/webapi-public/v2/content/${contentID}`;
  }
  async getVideoDetailContent(contentID) {
    try {
      const videoData = await this.doFetch(this.getVideoDetailEndPoint(contentID));
      return Object.assign(videoData, { description: path([0, 'text'], videoData.leadtext), web_url: videoData.url });
    } catch (e) {
      return emptyVideoDetail;
    }
  }

  //FE-48 --Vertical Video value fetch
  async getVerticalVideo(contentID) {
    try {
      const videoData = await this.doFetch(this.getVideoDetailEndPoint(contentID));
      return videoData.isvertical_video;
    } catch (e) {
      return emptyVideoDetail;
    }
  }
  // Video trending request
  getTrendingEndPoint(publication = 'kansascity') {
    return `${this.publicApiBase}/webapi-public/v2/publications/${publication}/trending/videos`;
  }
  // Video trending request
  getRelatedEndPoint(publication, brithcoveId) {
    return `${this.publicApiBase}/webapi-public/v2/publications/${publication}/related/${brithcoveId}`;
  }

  // Video relative request
  getRelatedVideos(publication, brithcoveId) {
    return this.doFetch(this.getRelatedEndPoint(publication, brithcoveId));
  }
  getTrendingVideos(publication) {
    return this.doFetch(this.getTrendingEndPoint(publication));
  }

  // Section request
  buildSectionPath(sectionId, limit, offset) {
    const finalOffset = (offset) ? `&offset=${offset}` : '';
    return `${this.publicApiBase}/webapi-public/v2/sections/${sectionId}/content?limit=${limit}${finalOffset}`;
  }
  getSectionContent(sectionId, limit, offset) {
    return this.doFetch(this.buildSectionPath(sectionId, limit, offset));
  }
  getBreakingNewsGroup(sectionId, groupName, index) {
    const url = `${this.publicApiBase}/webapi-public/v2/sections/${sectionId}/groups/${groupName}/index/${index}`;
    return this.doFetch(url);
  }
}

export default new ClientRequest(window.pageInfo.domainName);
